<template>
  <div class="addCoupon">
    <el-breadcrumb class="top" separator="/">
      <el-breadcrumb-item>添加/编辑满减</el-breadcrumb-item>
    </el-breadcrumb>
    <br/>
    <el-form ref="form" label-width="110px">
      <el-form-item label="满减名称">
        <el-input
          v-model="formList.title"
          style="width: 50%;"
          placeholder="请输入标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="标签">
        <el-input
          v-model="formList.label"
          style="width: 50%;"
          placeholder="请输入标签"
        ></el-input>
      </el-form-item>
      <div style="display:flex">
          <el-form-item label="每满">
            <el-input
              v-model="formList.fullFee"
              style="width:206px"
              placeholder="请输入金额"
              @input="inputChange($event,1)"
            ></el-input>
          </el-form-item>
          <el-form-item label="减">
            <el-input
              v-model="formList.minuFee"
                 style="width:206px"
              placeholder="请输入金额"
                  @input="inputChange($event,2)"
            ></el-input>
          </el-form-item>
      </div>
    
      <!-- <el-form-item label="所属分类">
         <el-select
        v-model="formList.cateId"
        placeholder="请选择"
      >
        <el-option
          v-for="item in cateOptions"
          :key="item.cateId"
          :label="item.cateName"
          :value="item.cateId"
        >
        </el-option>
      </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="优惠券价格" class="col">
        <el-input
          type="number"
          v-model="formList.couponPrice"
          placeholder="请输入优惠券价格"
        ></el-input>
      </el-form-item>
      <el-form-item label="可领取次数" class="col lingqu">
        <el-input
          type="number"
          v-model="formList.couponNum"
          placeholder="请输入领取次数"
        ></el-input>
        <div style="color: red; display: inline-block; margin-left: 10px">
          (0：表示不限次数，1：表示可以领取1次，2：表示可以领取2次，以此类推。。。)
        </div>
      </el-form-item>
      <el-form-item label="满减金额">
        <el-input
          type="number"
          v-model="formList.fullMoney"
          placeholder="请输入满减金额"
        ></el-input>
        <div style="color: red; display: inline-block; margin-left: 10px">
          (0：表示不限金额，100：一次购物满100可以使用，500：一次购物满500可以使用，以此类推)
        </div>
      </el-form-item>
      <el-form-item label="领取方式">
        <el-select v-model="formList.receiveWay" placeholder="请选择领取方式">
          <el-option
            v-for="item in receiveWayOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="使用时间">
        <el-date-picker
          v-model="formList.beginTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
        --
        <el-date-picker
          v-model="formList.endTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>

        <div style="color: red; display: inline-block; margin-left: 10px">
          <!-- (表示购买这个时间段的产品才可以使用) -->
        </div>
      </el-form-item>
      <!-- <el-form-item label="有效期类型">
        <el-radio v-model="formList.timeType" label="1"
          >指定开始时间和结束时间</el-radio
        >
        <el-radio v-model="formList.timeType" label="2">指定有效天数</el-radio>
      </el-form-item>
      <el-form-item label="有效天数" v-show="formList.timeType == '2'">
        <el-input
          v-model="effectiveDays"
          placeholder="请输入有效天数"
        ></el-input>
      </el-form-item>
      <el-form-item label="有效时间" v-show="formList.timeType == '1'">
        <el-date-picker
          v-model="beginTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
        --
        <el-date-picker
          v-model="endTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item> -->
        <!-- <el-form-item label="一键领取">
        <el-radio v-model="formList.oneKey" label="1">是</el-radio>
        <el-radio v-model="formList.oneKey" label="0">否</el-radio>
      </el-form-item>
        <el-form-item label="是否老用户回归券">
        <el-radio v-model="formList.isOld" label="1">是</el-radio>
        <el-radio v-model="formList.isOld" label="0">否</el-radio>
      </el-form-item> -->
      <el-form-item label="优惠券">
        <el-radio v-model="couponScopeValue" :label="-1">不使用任何优惠券</el-radio>
       
        <el-radio v-model="couponScopeValue" :label="0">全部优惠券可用</el-radio>
         <el-radio v-model="couponScopeValue" :label="1">选择优惠券</el-radio>
      </el-form-item>
      <el-form-item label="选择优惠券" v-show="couponScopeValue == 1">
       <el-button type="primary" @click="couDialogVisible = true"
          >选择优惠券</el-button
        >
      </el-form-item>
      <el-form-item label="选中优惠券" v-show="couponScopeValue == 1">
           <el-form-item
          label="优惠券名称:"
          v-for="item in couListData"
          :key="item.couponId"
          v-show="item.isShow"
          >{{ item.couponName }}
          <a
            href="javascript:;"
            style="color: red; margin-left: 10px"
            @click="onDelcouId(item.couponId, item)"
            >删除</a
          >
        </el-form-item>
      </el-form-item>
      <el-form-item label="选择产品" >
        <el-button type="primary" @click="roomDialogVisible = true"
          >选择客房</el-button
        >
        <el-button type="primary" @click="ticketDialogVisible = true"
          >选择门票</el-button
        >
        <el-button type="primary" @click="lineDialogVisible = true"
          >选择线路</el-button
        >
        <el-button type="primary" @click="seckDialogVisible = true"
          >选择秒杀</el-button
        >
        <el-button type="primary" @click="groupDialogVisible = true"
          >选择拼团</el-button
        >
        <el-button type="primary" @click="foodDialogVisible = true"
          >选择美食</el-button
        >
      </el-form-item>
      <el-form-item label="选择的产品" >
        <el-form-item
          label="客房名称:"
          v-for="item in roomListData"
          :key="item.roomId"
          v-show="item.isShow"
          >{{ item.roomName }}
          <a
            href="javascript:;"
            style="color: red; margin-left: 10px"
            @click="onDelRoomId(item.cateId, item)"
            >删除</a
          >
        </el-form-item>
        <!-- <el-form-item v-if="roomListData.length > 6">
          <div style="margin-left: 60px">展开全部</div>
        </el-form-item> -->
        <el-form-item
          label="门票名称:"
          v-for="item in ticketListData"
          :key="item.ticketId"
          v-show="item.isShow"
        >
          {{ item.ticketName }}
          <a
            href="javascript:;"
            style="color: red; margin-left: 10px"
            @click="onDelticketId(item.cateId, item)"
            >删除</a
          >
        </el-form-item>
        <!-- <el-form-item v-if="ticketListData.length > 6">
          <div style="margin-left: 60px">展开全部</div>
        </el-form-item> -->
        <el-form-item
          label="线路名称:"
          v-for="item in travelListData"
          :key="item.travelId"
          v-show="item.isShow"
          >{{ item.travelName }}
          <a
            href="javascript:;"
            style="color: red; margin-left: 10px"
            @click="onDeltravelId(item.cateId, item)"
            >删除</a
          >
        </el-form-item>
        <el-form-item
          label="秒杀名称:"
          v-for="item in seckListData"
          :key="item.seckillId"
          v-show="item.isShow"
          >{{ item.seckillName }}
          <a
            href="javascript:;"
            style="color: red; margin-left: 10px"
            @click="onDelseckId(item.seckillId, item)"
            >删除</a
          >
        </el-form-item>
        <el-form-item
          label="拼团名称:"
          v-for="item in groupListData"
          :key="item.groupBuyId"
          v-show="item.isShow"
          >{{ item.groupBuyName }}
          <a
            href="javascript:;"
            style="color: red; margin-left: 10px"
            @click="onDelgroupId(item.groupBuyId, item)"
            >删除</a
          >
        </el-form-item>
        <el-form-item
          label="美食名称:"
          v-for="item in foodList"
          :key="item.foodId"
         
          >{{ item.secondTitle }}
          <a
            href="javascript:;"
            style="color: red; margin-left: 10px"
            @click="onDelFood(item, item)"
            >删除</a
          >
        </el-form-item>
        <!-- <el-form-item v-if="travelListData.length > 6">
            <div style="margin-left: 60px">展开全部</div>
          </el-form-item> -->
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="onSave">保存</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      title="选择客房"
      class="addHotel"
      :visible.sync="roomDialogVisible"
      width="55%"
    >
      <div class="search">
        <span>客房名称:</span>
        <el-input
          v-model="roomList.roomName"
          placeholder="请输入房型名称"
        ></el-input>
        <span>所属酒店:</span>
        <el-select
          clearable
          @clear="onClear(2)"
          v-model="roomList.hotelId"
          placeholder="请选择所属酒店"
        >
          <el-option
            v-for="item in hotelOptions"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getRoomList()">查询</el-button>
      </div>
      <el-table
        @selection-change="onSelectChange"
        border
        :data="roomTableData"
        style="width: 100%"
      >
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column
          align="center"
          prop="roomName"
          label="客房名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="hotelName"
          label="所属酒店"
        ></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="onAddRoomRow(row.roomId)"
              :disabled="
                roomIds.indexOf(Number(row.roomId)) !== -1 ? true : false
              ">添加</el-button>
            
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="roomList.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="roomList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="roomPagination.total"
      >
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddRoom"
        >添加选中项</el-button
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="roomDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="roomDialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择门票"
      class="addHotel"
      :visible.sync="ticketDialogVisible"
      width="55%"
    >
      <div class="search">
        <span>门票名称:</span>
        <el-input
          v-model="ticketList.ticketName"
          placeholder="请输入门票名称"
        ></el-input>
        <span>所属门票:</span>
        <el-select
          clearable
          @clear="onClear(3)"
          v-model="ticketList.sceneryId"
          placeholder="请选择所属景区"
        >
          <el-option
            v-for="item in ticketOptions"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
        <el-button type="primary" @click="getTicketList()">查询</el-button>
      </div>
      <el-table
        @selection-change="onSelectChange1"
        border
        :data="ticketTableData"
        style="width: 100%"
      >
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column
          align="center"
          prop="ticketName"
          label="门票名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sceneryName"
          label="所属景区"
        ></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="onAddTicketRow(row.ticketId)"
              :disabled="ticketIds.indexOf(row.ticketId) !== -1 ? true : false"
              >添加</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page="ticketList.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="ticketList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="ticketPagination.total"
      >
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddTicket"
        >添加选中项</el-button
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="ticketDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ticketDialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择线路"
      class="addHotel"
      :visible.sync="lineDialogVisible"
      width="55%"
    >
      <div class="search">
        <span>线路名称:</span>
        <el-input
          v-model="travelList.travelName"
          placeholder="请输入线路名称"
        ></el-input>
        <el-button type="primary" @click="getLineList()">查询</el-button>
      </div>
      <el-table
        @selection-change="onSelectChange2"
        border
        :data="travelTableData"
        style="width: 100%"
      >
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column
          align="center"
          prop="travelName"
          label="线路名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="cateName"
          label="所属分类"
        ></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="onAddtravelRow(row.travelId)"
              :disabled="travelIds.indexOf(row.travelId) !== -1 ? true : false"
              >添加</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange2"
        @current-change="handleCurrentChange2"
        :current-page="travelList.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="travelList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="travelPagination.total"
      >
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddtravel"
        >添加选中项</el-button
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="lineDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="lineDialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择秒杀"
      class="addHotel"
      :visible.sync="seckDialogVisible"
      width="55%"
    >
      <div class="search">
        <span>秒杀名称:</span>
        <el-input
          v-model="seckList.seckillName"
          placeholder="请输入秒杀名称"
        ></el-input>
        <el-button type="primary" @click="getSeckList()">查询</el-button>
      </div>
      <el-table
        @selection-change="onSelectChange3"
        border
        :data="seckTableData"
        style="width: 100%"
      >
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column
          align="center"
          prop="seckillName"
          label="秒杀名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="cateName"
          label="所属分类"
        ></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="onAddseckRow(row.seckillId)"
              :disabled="seckIds.indexOf(row.seckillId) !== -1 ? true : false"
              >添加</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange3"
        @current-change="handleCurrentChange3"
        :current-page="seckList.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="seckList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="secktotal"
      >
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddSeck"
        >添加选中项</el-button
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="seckDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="seckDialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择拼团"
      class="addHotel"
      :visible.sync="groupDialogVisible"
      width="55%"
    >
      <div class="search">
        <span>拼团名称:</span>
        <el-input
          v-model="groupList.groupBuyName"
          placeholder="请输入拼团名称"
        ></el-input>
        <el-button type="primary" @click="getGroupList()">查询</el-button>
      </div>
      <el-table
        @selection-change="onSelectChange4"
        border
        :data="groupTableData"
        style="width: 100%"
      >
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column
          align="center"
          prop="groupBuyName"
          label="拼团名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="cateName"
          label="所属分类"
        ></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="onAddgroupRow(row.groupBuyId)"
              :disabled="groupIds.indexOf(row.groupBuyId) !== -1 ? true : false"
              >添加</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange4"
        @current-change="handleCurrentChange4"
        :current-page="groupList.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="groupList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="grouptotal"
      >
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddGroup"
        >添加选中项</el-button
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="groupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="groupDialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="美食"
      class="addHotel"
      :visible.sync="foodDialogVisible"
      width="55%"
    >
    <foodList :status="2" @submit="submit" @change="foodChange"></foodList>
    <el-button class="add" type="primary" @click="onAddFood"
        >添加选中项</el-button
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="foodDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="foodDialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="选择优惠券"
      class="addHotel"
      :visible.sync="couDialogVisible"
      width="55%"
    >
      <div class="search">
        <span>优惠券名称:</span>
        <el-input
          v-model="couList.couponName"
          placeholder="请输入优惠券名称"
        ></el-input>
        <el-button type="primary" @click="getCouList()">查询</el-button>
      </div>
      <el-table
        @selection-change="onSelectChange5"
        border
        :data="couTableData"
        style="width: 100%"
      >
        <el-table-column align="center" type="selection"></el-table-column>
        <el-table-column
          align="center"
          prop="couponName"
          label="优惠券名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="couponPrice"
          label="优惠券价格"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="strScope"
          label="使用范围"
        ></el-table-column>
        <el-table-column align="center" prop="strStatus" label="上下架">
        </el-table-column>
        <el-table-column align="center" prop="isShow" label="是否显示">
        </el-table-column>
        <el-table-column align="center" prop="wlShow" label="万旅网显示">
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="primary"
              size="mini"
              @click="onAddcouRow(row.couponId)"
              :disabled="couIds.indexOf(row.couponId) !== -1 ? true : false"
              >添加</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        @size-change="handleSizeChange5"
        @current-change="handleCurrentChange5"
        :current-page="couList.currentPage"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="couList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="coutotal"
      >
      </el-pagination>
      <el-button class="add" type="primary" @click="onAddCou"
        >添加选中项</el-button
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="groupDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="couDialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { allHotel } from "../../../api/hotelList";
import { roomList, roomDetails } from "../../../api/room";
import { ticketList, ticketDetails } from "../../../api/ticket";
import { addScenery, allScenery } from "../../../api/sceneryList";
import { lineList, lineDetails } from "../../../api/lineList";
import { seckillInfoList, seckillInfoDetail } from "../../../api/seckillInfo";
import { groupBuyList, groupBuyDetail } from "../../../api/groupBuy";
import { couponList,couponDetail } from "../../../api/coupon";
import { addDeceList, deceListDetails} from "../../../api/fullDecrement";
import foodList from '../../food/foodList.vue';
export default {
  name: "addFullDecrement",
  components: {
    foodList
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      foodDialogVisible:false,
      secktotal:0,
      cateOptions:[],
      roomDialogVisible: false,
      ticketDialogVisible: false,
      lineDialogVisible: false,
      seckDialogVisible: false,
      groupDialogVisible: false,
      couDialogVisible: false,
      roomName: "",
      hotelOptions: [],
      roomListData: [],
      roomListIds: [],
      roomList: {
        hotelId: null,
        currentPage: 1,
        pageSize: 5,
        roomName: "",
      },
        seckListIds:[],
         seckTableData:[],
          seckListData: [],
          seckIds:[],
      seckList:{
          currentPage: 1,
           pageSize: 5,
           seckillName:''
      },
      groupTableData:[],
      groupListIds:[],
      grouptotal:0,
      groupIds:[],
      
      groupListData:[],
      groupList:{
          currentPage: 1,
           pageSize: 5,
           groupBuyName:''
      },

      couTableData:[],
      couListIds:[],
      couListData:[],
      
      couIds:[],
      couList:{
        currentPage: 1,
           pageSize: 5,
           couponName:''
      },
      coutotal:0,


      roomPagination: {},
      roomTableData: [],
   
      hotelSelectId: null,
      ticketListData: [],
      ticketListIdsData:[],
      ticketList: {
        currentPage: 1,
        pageSize: 5,
        ticketName: "",
        sceneryId: null,
      },
      ticketListIds: [],
      ticketOptions: [],
      ticketTableData: [],
      ticketPagination: {},
      travelListData: [],
     
      travelList: {
        currentPage: 1,
        pageSize: 5,
        travelName: "",
      },
      travelTableData: [],
      travelListIds: [],
      travelPagination: {},
     travelListIdsData:[],
      oneKey:'0',
      couponScopeValue: 0,
      formList: {
        fullId:0,
        title: "",
        minuFee: '',
        beginTime: '',
        endTime:'',
        fullFee: '',
        label:"",

      },
      couponScope: 0,
      effectiveDays: "",
    
      beginTime: "",
      endTime: "",
      roomIds: [],
      ticketIds: [],
      travelIds: [],
      
      allRoom: [],
      allTicket: [],
      allLine: [],
      foodList: [],
      foodListChange: [],
    };
  },
  created() {
    this.getAllHotel();
    this.getRoomList();
    this.getTicketList();
    this.getAllscenery();
    this.getLineList();
    this.getSeckList();
    this.getGroupList();
    this.getCouList();
    // this.getCate();
    if (Number(this.id) !== 0) {
      this.getfullDetail();
    }
  },

  watch: {
    couponScopeValue: function () {
      this.couponScope = Number(this.couponScopeValue);
    },
  },
  methods: {
//             async getCate(){
//       const {data} = await couponAllCateAPI()
//         console.log(data,'data')
//         this.cateOptions = data.data
//     },
    onDelRoomId(id, item) {
      item.isShow = false;
      console.log(id);
      this.roomIds = this.roomIds.filter((item) => {
        return item != id;
      });
      console.log()
      // this.getroomDetails();
    },
    onDelticketId(id, item) {
      item.isShow = false;

      this.ticketIds = this.ticketIds.filter((item) => {
        return item != id;
      });


      // this.getticketDetails();
    },
    onDeltravelId(id, item) {
      item.isShow = false;

      this.travelIds = this.travelIds.filter((item) => {
        return item != id;
      });
      console.log(id,this.travelIds)

      // this.getlineDetails();
    },
    onDelseckId(id, item) {
      item.isShow = false;

      this.seckIds = this.seckIds.filter((item) => {
        return item != id;
      });
      // this.getlineDetails();
    },
    onDelgroupId(id, item) {
      item.isShow = false;

      this.groupIds = this.groupIds.filter((item) => {
        return item != id;
      });
      // this.getlineDetails();
    },
    onDelcouId(id, item) {
      item.isShow = false;

      this.couIds = this.couIds.filter((item) => {
        return item != id;
      });
      // this.getlineDetails();
    },
    submit(e){
      console.log(e)
      let map = new Map()
      this.foodList = [...this.foodList,...[e]].filter(item=> !map.has(item.foodId) && map.set(item.foodId,item.foodId))
      console.log(this.foodList)
      this.$message.success('添加成功')
    },
    foodChange(arr){
      this.foodListChange = arr
    },
    onAddFood(){
      let map = new Map()
      this.foodList = [...this.foodList,...this.foodListChange].filter(item=> !map.has(item.foodId) && map.set(item.foodId,item.foodId))
      this.$message.success('添加成功')
    },
    onDelFood(item){
      let index = this.foodList.findIndex(val=>val.foodId == item.foodId)
      if(index != -1){
        this.foodList.splice(index,1)
      }
    },
    async getfullDetail() {
      const { data } = await deceListDetails({
        fullId: Number(this.id),
      });
      console.log(data,'data')
       this.formList.fullId = data.data.fullId;
       this.formList.title = data.data.title;
       this.formList.minuFee = data.data.minusFee;
       this.formList.beginTime = data.data.beginTime;
       this.formList.endTime = data.data.endTime;
       this.formList.fullFee = data.data.fullFee;
       this.formList.label = data.data.label;
       
      
      
     
      // this.formList.couponName = data.data[0].couponName;
      // this.formList.couponPrice = data.data[0].couponPrice;
      // this.formList.fullMoney = data.data[0].fullMoney;
      // this.formList.couponNum = data.data[0].couponNum;
      // this.formList.receiveWay = data.data[0].receiveWay;
      // this.formList.useBeginTime = data.data[0].useBeginTime;
      // this.formList.useEndTime = data.data[0].useEndTime;
      // this.formList.oneKey = data.data[0].oneKey + "";
      // this.formList.timeType = data.data[0].timeType + "";
      // this.formList.cateId = data.data[0].cateId;
      // this.formList.isOld = data.data[0].isOld+'';
      // this.beginTime = data.data[0].beginTime;
      // this.endTime = data.data[0].endTime;
      // this.effectiveDays = data.data[0].effectiveDays;
      // this.couponScopeValue = data.data[0].couponScope + "";


      if (data.data.roomIds) {
        this.roomIds = data.data.roomIds.split(",").map((i) => {
          return Number(i);
        });
        const arr =  data.data.roomModel.map( (item) => {
          item.roomName = item.cateName
          item.isShow = true;
         return item
        });
        console.log(arr,'arrarr')

      this.roomListData = arr;
        // this.getroomDetails();
      }
      if (data.data.ticketIds) {
        this.ticketIds = data.data.ticketIds.split(",").map((i) => {
          return Number(i);
        });
         const arr =  data.data.ticketModel.map( (item) => {
          item.ticketName = item.cateName
          item.isShow = true;
         return item
        });
        console.log(arr,'arrarr')

      this.ticketListData = arr

        // this.getticketDetails();
      }
      if (data.data.travelIds) {
        this.travelIds = data.data.travelIds.split(",").map((i) => {
          return Number(i);
        });
        const arr =  data.data.travelModel.map( (item) => {
          item.travelName = item.cateName
          item.isShow = true;
          return item
        });
        console.log(arr,'arrarr')

      this.travelListData = arr
        // this.getlineDetails();
      }
      if (data.data.seckillIds) {
        this.seckIds = data.data.seckillIds.split(",").map((i) => {
          return Number(i);
        });
        // this.getSeckDetails();
         const arr =  data.data.seckillModel.map( (item) => {
          item.seckillName = item.cateName
          item.isShow = true;
         return item
        });
        console.log(arr,'arrarr')

      this.seckListData = arr
      }
      if (data.data.groupBuyIds) {
        this.groupIds = data.data.groupBuyIds.split(",").map((i) => {
          return Number(i);
        });
           const arr =  data.data.groupModel.map( (item) => {
          item.groupBuyName = item.cateName
          item.isShow = true;
         return item
        });
        console.log(arr,'arrarr')

      this.groupListData = arr
        // this.getGroupDetails();
      }
      if (data.data.foodIds) {
        this.foodList = data.data.foodModel.map((item) => {
          return {
            secondTitle:item.cateName,
            foodId:item.cateId
          }
        });

      }
   
      let couponIds =  data.data.couponIds
      let couponIdsArr = couponIds.split(",")
      console.log(couponIds.indexOf(","))
      console.log(couponIds.length)
      console.log(couponIdsArr.length)

      if( Number(couponIds) == -1 || Number(couponIds) == 0){
        this.couponScopeValue = Number(couponIds)
      }else{
        this.couponScopeValue = 1
        this.couIds = data.data.couponIds.split(",").map((i) => {
          return Number(i);
        });
        this.getcouDetails();

      }
       console.log(this.couponScopeValue)
    },
    inputChange(e,type){
      console.log(e)
      console.log(type)
      if(type == 1 && isNaN(e)){
        this.formList.fullFee = ''
        this.$message({
            showClose: true,
            message: "请输入数字",
            type: "error",
          });
      }
       if(type == 2 && isNaN(e)){
        this.formList.minuFee = ''
        this.$message({
            showClose: true,
            message: "请输入数字",
            type: "error",
          });
      }
    },
    async onSave() {
      for (const key in this.formList) {
        if (this.formList[key] === null || this.formList[key] === "") {
          return this.$message({
            showClose: true,
            message: "请把数据填写完整",
            type: "error",
          });
        }
      }
      
      if (
        this.couponScope == 1 &&
        this.roomIds == "" &&
        this.ticketIds == "" &&
        this.seckIds == "" &&
        this.groupIds == "" &&
        
        this.travelIds == "" &&
        this.foodList.length == 0
      ) {
        return this.$message({
          showClose: true,
          message: "请选择产品",
          type: "error",
        });
      }
      // if (
      //   this.formList.timeType == "1" &&
      //   (this.beginTime == "" || this.endTime == "")
      // ) {
      //   return this.$message({
      //     showClose: true,
      //     message: "在指定开时间和结束时间,请务必选择开始和结束时间",
      //     type: "error",
      //   });
      // } else if (this.formList.timeType == "2" && this.effectiveDays == "") {
      //   return this.$message({
      //     showClose: true,
      //     message: "在指定有效天数,请务必选择有效天数",
      //     type: "error",
      //   });
      // }
      let params = {
        ...this.formList,
       
        roomIds: this.roomIds.join(","),
        ticketIds: this.ticketIds.join(","),
        travelIds: this.travelIds.join(","),
        seckillIds: this.seckIds.join(","),
        groupBuyIds: this.groupIds.join(","),
        foodIds:this.foodList.map(item=> item.foodId).join(',')
      }
      if(this.couponScopeValue == 1 &&  !this.couIds.join(",")){
        return this.$message({
          showClose: true,
          message: "请选择优惠券",
          type: "error",
        });
      }
       else if(this.couponScopeValue == 1 &&  this.couIds.join(",")){
       params.couponIds = this.couIds.join(",")
      }else{
        params.couponIds = this.couponScopeValue
         params.couponIds =  params.couponIds + ""
      }

      console.log(params)
      // return
      const { data } = await addDeceList(params);
      if (data.code == 0) {
        this.$message({
          showClose: true,
          message: data.msg,
          type: "success",
        });
        this.$router.go(-1);
      } else {
        this.$message({
          showClose: true,
          message: data.msg,
          type: "error",
        });
      }
    },
    async getroomDetails() {
      // var arr = [];
      // this.roomIds.forEach(async (item) => {
      //   const { data } = await roomDetails({
      //     roomId: Number(item),
      //   });
      //   data.data[0].isShow = true;
      //   arr.push(data.data[0]);
      // });

      // this.roomListData = arr;
        this.roomListIdsData.forEach(key=>{
        let index = this.roomListData.findIndex(key2=> key.cateId == key2.cateId)
        if(index == -1){
          this.roomListData.push(key)
        }
      })
    },
    async getticketDetails() {
      // var arr = [];
      // this.ticketIds.forEach(async (item) => {
      //   const { data } = await ticketDetails({
      //     ticketId: Number(item),
      //   });
      //   data.data[0].isShow = true;
      //   arr.push(data.data[0]);
      // });
      // this.ticketListData = arr;
        this.ticketListIdsData.forEach(key=>{
        let index = this.ticketListData.findIndex(key2=> key.cateId == key2.cateId)
        if(index == -1){
          this.ticketListData.push(key)
        }
      })
      

    },
    async getlineDetails() {
      // var arr = [];
      // this.travelIds.forEach(async (item) => {
      //   const { data } = await lineDetails({
      //     travelId: Number(item),
      //   });
      //   data.data[0].isShow = true;

      //   arr.push(data.data[0]);
      // });
      // this.travelListData = arr;
      // console.log(this.ticketListData, "1qweqweqw");
        this.travelListIdsData.forEach(key=>{
        let index = this.travelListData.findIndex(key2=> key.cateId == key2.cateId)
        if(index == -1){
          this.travelListData.push(key)
        }
      })
      
      //  this.travelListData =  Array.from(new Set(this.travelListData));
  console.log(this.travelListData,'this.travelListDatathis.travelListData')
    },
    async getSeckDetails() {
      var arr = [];
      this.seckIds.forEach(async (item) => {
        const { data } = await seckillInfoDetail({
          seckillId: Number(item),
        });
        data.data[0].isShow = true;

        arr.push(data.data[0]);
      });
      this.seckListData = arr;
      console.log(this.seckListData, "seckListData");
    },
    async getGroupDetails() {
      var arr = [];
      this.groupIds.forEach(async (item) => {
        const { data } = await groupBuyDetail({
          groupBuyId: Number(item),
        });
        data.data[0].isShow = true;

        arr.push(data.data[0]);
      });
      this.groupListData = arr;
      console.log(this.groupListData, "groupListData");
    },
    async getcouDetails() {
      var arr = [];
      this.couIds.forEach(async (item) => {
        const { data } = await couponDetail({
          couponId: Number(item),
        });
        data.data[0].isShow = true;

        arr.push(data.data[0]);
      });
      this.couListData = arr;
      console.log(this.couListData, "groupListData");
    },
    async getAllHotel() {
      const { data } = await allHotel();
      this.hotelOptions = data.data;
    },
    async getRoomList() {
      const { data } = await roomList(this.roomList);
      this.roomTableData = data.list;
      this.roomPagination = data.pagination;
    },
    async getAllscenery() {
      const { data } = await allScenery();
      this.ticketOptions = data.data;
    },
    async getTicketList() {
      const { data } = await ticketList(this.ticketList);
      this.ticketTableData = data.list;
      this.ticketPagination = data.pagination;
    },
    async getLineList() {
      const { data } = await lineList(this.travelList);
      console.log(data, "12121");
      this.travelTableData = data.list;
      this.travelPagination = data.pagination;
    },
    async getSeckList() {
      const { data } = await seckillInfoList(this.seckList);
      console.log(data, "12121");
      this.seckTableData = data.list;
      this.secktotal = data.pagination.total;
    },
    async getGroupList() {
      const { data } = await groupBuyList(this.groupList);
      console.log(data, "12121");
      this.groupTableData = data.list;
      this.grouptotal = data.pagination.total;
    },
    async getCouList() {
      const { data } = await couponList(this.couList);
      console.log(data, "12121");
      this.couTableData = data.list;
      this.coutotal = data.pagination.total;
    },
    onAddtravelRow(id) {
      if (this.travelIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    
      this.travelIds.push(id);
        this.getlineDetails();
    },
    onAddseckRow(id) {
      if (this.seckIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.getSeckDetails();
      this.seckIds.push(id);
    },
    onAddgroupRow(id) {
      if (this.groupIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.groupIds.push(id);
      this.getGroupDetails();
      
    },
    onAddcouRow(id) {
      if (this.couIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.couIds.push(id);
      this.getcouDetails();
      
    },
    onAddtravel() {
      if (this.travelListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.travelIds.push(...this.travelListIds);
      this.travelIds = Array.from(new Set(this.travelIds));
    
      this.getlineDetails();
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onAddSeck() {
      if (this.seckListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.seckIds.push(...this.seckListIds);
      this.seckIds = Array.from(new Set(this.seckIds));
      this.getSeckDetails();
      
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onAddGroup() {
      if (this.groupListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.groupIds.push(...this.groupListIds);
      this.groupIds = Array.from(new Set(this.groupIds));
      this.getGroupDetails();
      
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onAddCou() {
      if (this.couListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.couIds.push(...this.couListIds);
      this.couIds = Array.from(new Set(this.couIds));
      this.getcouDetails();
      
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onSelectChange2(selection) {
      var arr = [];
        var arr2 = []
      selection.map((item) => {
        arr.push(item.travelId);
        arr2.push(item)
      });
         this.travelListIdsData = arr2.map(item2=>{

          item2.isShow = true
          item2.cateId = item2.travelId
          return item2
         })
    
      this.travelListIds = arr;
      
    },
    onSelectChange3(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.seckillId);
      });
      this.seckListIds = arr;
    },
    onSelectChange4(selection) {
      var arr = [];
    
      selection.map((item) => {
        arr.push(item.groupBuyId);
        
      });
       this.groupListIds = arr;

   
    },
    onSelectChange5(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.couponId);
      });
      this.couListIds = arr;
    },
    onAddTicketRow(id) {
      if (this.ticketIds.indexOf(id) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.ticketIds.push(id);
      this.getticketDetails();
      console.log(this.ticketIds);
    },
    onAddTicket() {
      if (this.ticketListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.ticketIds.push(...this.ticketListIds);
      this.ticketIds = Array.from(new Set(this.ticketIds));
      // this.ticketListData.push() 
      this.getticketDetails();
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
    },
    onSelectChange1(selection) {
      var arr = [];
      var arr2 = []
      selection.map((item) => {
        arr.push(item.ticketId);
        arr2.push(item)
      });
      console.log(this.travelListIds,'travelIds')
    
     
      this.ticketListIdsData = arr2.map(item2=>{

          item2.isShow = true
          item2.cateId = item2.ticketId
          return item2
         })
      this.ticketListIds = arr;
       console.log(this.ticketListIds,'ticketListIds')
    },
    onSelectChange(selection) {
      var arr = [];
      var arr2 = []
      selection.map((item) => {
        arr.push(item.roomId);
        arr2.push(item)
      });
        this.roomListIdsData = arr2.map(item2=>{

          item2.isShow = true
          item2.cateId = item2.roomId
          return item2
         })
      
      this.roomListIds = arr;
    },
    onAddRoomRow(id) {
      if (this.roomIds.indexOf(Number(id)) !== -1) {
        return this.$message({
          showClose: true,
          message: "数据已添加",
          type: "warning",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.roomIds.push(id);
      this.getroomDetails();
    },
    onAddRoom() {
      if (this.roomListIds == "") {
        return this.$message({
          showClose: true,
          message: "请选择要添加数据",
          type: "error",
        });
      }
      this.$message({
        showClose: true,
        message: "添加成功",
        type: "success",
      });
      this.roomIds.push(...this.roomListIds);
      this.roomIds = Array.from(new Set(this.roomIds));
      this.getroomDetails();

      // this.roomDialogVisible = false;
    },
    onClear(value) {
      if (value === 2) {
        this.roomList.hotelId = null;
      } else if (value === 3) {
        this.ticketList.sceneryId = null;
      }
    },
    handleSizeChange(num) {
      this.roomList.pageSize = num;
      this.getRoomList();
    },
    handleCurrentChange(num) {
      this.roomList.currentPage = num;
      this.getRoomList();
    },
    handleSizeChange1(num) {
      this.ticketList.pageSize = num;
      this.getTicketList();
    },
    handleCurrentChange1(num) {
      this.ticketList.currentPage = num;
      this.getTicketList();
    },
    handleSizeChange2(num) {
      this.travelList.pageSize = num;
      this.getLineList();
    },
    handleCurrentChange2(num) {
      this.travelList.currentPage = num;
      this.getLineList();
    },
    handleSizeChange3(num) {
      this.seckList.pageSize = num;
      this.getSeckList();
    },
    handleCurrentChange3(num) {
      this.seckList.currentPage = num;
      this.getSeckList();
    },
    handleSizeChange4(num) {
      this.groupList.pageSize = num;
      this.getGroupList();
    },
    handleCurrentChange4(num) {
      this.groupList.currentPage = num;
      this.getGroupList();
    },
    handleSizeChange5(num) {
      this.couList.pageSize = num;
      this.getCouList();
    },
    handleCurrentChange5(num) {
      this.couList.currentPage = num;
      this.getCouList();
    },
  },
};
</script>
<style lang="less" scoped>
.addCoupon {

  .top {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
 
  .btn {
    margin-top: 50px;
    margin-left: 20px;
  }
  /deep/ .addHotel {
    .el-dialog {
      .el-dialog__body {
        .el-pagination {
          margin-top: 20px;
          float: right;
        }
        .add {
          margin-top: 20px;
        }
        .el-table {
          margin-top: 50px;
        }
        .search {
          span {
            margin: 0 20px;
          }
          .el-input {
            width: 300px;
          }
          .el-select {
            width: 300px !important;
          }
          .el-button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>