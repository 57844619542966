import request from '../utils/request'
import baseUrl from './baseUrl'
//满减列表
export const deceList = (data) => request({
    url: baseUrl + '/fullMinus/selectForBack',
    method: 'POST',
    data
  })
  // 添加/编辑
export const addDeceList = (data) => request({
    url: baseUrl + '/fullMinus/add',
    method: 'POST',
    data
  })
  // 查看详情
export const deceListDetails = (data) => request({
    url: baseUrl + '/fullMinus/selectForId',
    method: 'POST',
    data
  })
  // 失效
export const deceListInvalid = (data) => request({
    url: baseUrl + '/fullMinus/modifyStatus',
    method: 'POST',
    data
  })